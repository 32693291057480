import React,{useEffect, useState} from 'react';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { take } from 'rxjs/operators';
import { format, parseISO } from "date-fns";
import {
  selectUser$,
  getCustomerOrders
} from '../../../state/user/user.service';
import {
  selectCheckout$,
} from '../../../state/checkout/checkout.service';
import Img from './../../../assets/images/step-final.png';
import * as Styled from './../styles'

const width = {width:`100%`};
const TrackOrder = ({ locale }) => {
  const [orders, setOrders] = useState([]);
  const [unMount$] = useState(new Subject());
  const [user, setUser] = useState(null);
  const [checkoutData, setCheckoutData] = useState(null);

  useEffect(() => {
    selectUser$.pipe(takeUntil(unMount$)).subscribe((user) => {
      setUser(user);
    });

    selectCheckout$.pipe(takeUntil(unMount$)).subscribe((data) => {
      setCheckoutData(data);
    });


    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  useEffect(() => {
    getCustomerOrders()
      .pipe(take(1))
      .subscribe((customerOrders) => {
        setOrders(customerOrders);
      });
  }, []);

  const formatDate = (date) => {
    if (!date) {
      return ''
    }

    try {
      if (typeof date === 'object') {
        return format(date, 'PPpp')
      }

      if (typeof date === 'string') {
        return format(parseISO(date), 'PPpp')
      }
      return format(date, 'PPpp')
    } catch (e) {
      return format(parseISO(date), 'PPpp')
    }
  }

  return (
    <Styled.Track>
        <div className='bgWrap'>

          <div className='header'>
            <label htmlFor="greetings">Hi {user?.first_name}, your driver has arrived in your location</label>
            <p>Estimated time of delivery</p>
            <span>{!!checkoutData?.delivery_due_date && (<p>{formatDate(checkoutData.delivery_due_date)}</p>)}</span>
          </div>

          <div className='text-center mt-4'>
            <img src={Img} alt='' className='w-full'/>
          </div>

          <div className='orderDetails'>
            <div className='column'>
              <div className='group'>
                <label htmlFor="shipping">Shipping to:</label>
                <p>
                  {orders[orders.length-1]?.customer_address}
                </p>
              </div>

              <div className='group'>
                <label htmlFor="contact">Contact Number</label>
                <p>{user?.phone}</p>
              </div>
            </div>

            <div className='column'>
              <div className='address'>
                <label htmlFor="note">Note to driver</label>
                <p>{!!checkoutData?.special_instructions && (<p>{checkoutData.special_instructions}</p>)}</p>
              </div>

            </div>
          </div>
        </div>

        <div className="w-full mx-auto my-4 pb-4 flow-root mt-8">
            <div className="flex pb-3">
                <div className="flex-1">
                </div>

                <div className="flex-2">
                    <div className="w-10 h-10 bg-yellow-200 mx-auto rounded-full text-lg text-white flex items-center">
                    <span className="text-white text-center w-full"><i className="fa fa-check w-full fill-current white"></i></span>
                    </div>
                </div>


                <div className="w-1/6 align-center items-center align-middle content-center flex">
                    <div className="w-full bg-yellow-300 items-center align-middle align-center flex-1">
                    <div className="bg-yellow-200 text-xs leading-none py-1 text-center text-grey-darkest " style={ width }/>
                    </div>
                </div>


                <div className="flex-2">
                    <div className="w-10 h-10 bg-yellow-200 mx-auto rounded-full text-lg text-white flex items-center">
                    <span className="text-white text-center w-full"><i className="fa fa-check w-full fill-current white"/></span>
                    </div>
                </div>

                <div className="w-1/6 align-center items-center align-middle content-center flex">
                    <div className="w-full bg-yellow-200 items-center align-middle align-center flex-1">
                    <div className="bg-yellow-200 text-xs leading-none py-1 text-center text-grey-darkest " style={ width }/>
                    </div>
                </div>

                <div className="flex-2">
                    <div className="w-10 h-10 bg-yellow-200 mx-auto rounded-full text-lg text-white flex items-center">
                    <span className="text-grey-darker text-center w-full"/>
                    </div>
                </div>


                <div className="w-1/6 align-center items-center align-middle content-center flex">
                    <div className="w-full bg-yellow-200 items-center align-middle align-center flex-1">
                    <div className="bg-yellow-200 text-xs leading-none py-1 text-center text-grey-darkest " style={ width }/>
                    </div>
                </div>


                <div className="flex-2">
                    <div className="w-10 h-10 bg-yellow-200 mx-auto rounded-full text-lg text-white flex items-center">
                    <span className="text-grey-darker text-center w-full"/>
                    </div>
                </div>


                <div className="flex-1">
                </div>
            </div>
        </div>

    </Styled.Track>
  );
};

export default TrackOrder;
