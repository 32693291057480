import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Complete from '../components/TrackOrder/Complete';
import { I18nextContext } from "gatsby-plugin-react-i18next";

const TrackCompletePage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);
  return (
    <Layout locale={language}>
        <SEO title="Complete Order" />
        <Complete locale={language}/>
    </Layout>
  );
};

export default TrackCompletePage;
